.collaborator-view-container {
  margin-top: 25px;
}

.collaborator-toggle {
  margin-top: 15px;
  margin-left: 8px;
}

.collaborator-email {
  margin-top: -10px;
}

/* .persona-collaborator {
  text-align-last: center;
} */

.collaborator-fullName {
  float: right;
}

.hidden {
  display: none;
}

.persona-class {
  justify-content: center;
  /* margin-bottom: -20px; */
}

.text-icon-collaborator {
  font-size: 12px;
}

.persona-detail-class {
  margin-top: -30px;
  margin-bottom: -30px;
}

/* .persona-class > div {
  margin-top: -50px;
} */

/* .persona-collaborator > div > div {
  justify-content: center;
} */

.persona-collaborator-text {
  font-size: 11px;
  color: gray;
}

.persona-collaborator-title {
  font-size: 80%;
  color: gray;
}

.collaborator-area {
  font-size: 120%;
}

.areaYDireccion {
  font-size: 16px;
}
